// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-igorko-redirect-js": () => import("D:\\Work\\Government of Children\\government-of-children-gastby\\.cache\\@igorko\\redirect.js" /* webpackChunkName: "component---cache-igorko-redirect-js" */),
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("D:\\Work\\Government of Children\\government-of-children-gastby\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("D:\\Work\\Government of Children\\government-of-children-gastby\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-become-js": () => import("D:\\Work\\Government of Children\\government-of-children-gastby\\src\\pages\\become.js" /* webpackChunkName: "component---src-pages-become-js" */),
  "component---src-pages-explore-js": () => import("D:\\Work\\Government of Children\\government-of-children-gastby\\src\\pages\\explore.js" /* webpackChunkName: "component---src-pages-explore-js" */),
  "component---src-pages-index-js": () => import("D:\\Work\\Government of Children\\government-of-children-gastby\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inhabit-js": () => import("D:\\Work\\Government of Children\\government-of-children-gastby\\src\\pages\\inhabit.js" /* webpackChunkName: "component---src-pages-inhabit-js" */),
  "component---src-pages-team-js": () => import("D:\\Work\\Government of Children\\government-of-children-gastby\\src\\pages\\team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-terms-js": () => import("D:\\Work\\Government of Children\\government-of-children-gastby\\src\\pages\\terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-videos-dashboard-js": () => import("D:\\Work\\Government of Children\\government-of-children-gastby\\src\\pages\\videos-dashboard.js" /* webpackChunkName: "component---src-pages-videos-dashboard-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "D:\\Work\\Government of Children\\government-of-children-gastby\\.cache\\data.json")

